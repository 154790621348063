// 'server-only';
'use client';
import {
  SiteFooter,
  // SwiperLayout,
  // SwiperSlide,
  // SwiperSlideContent,
  // ASPECT_16_9,
  // BannerWrapper,
  // SiteHeader,
} from '@demeter/musiclocal-react';
import {
  Button,
  Skeleton,
  // Carousel,
  // CarouselContent,
  // CarouselItem,
  // Card,
  // CardContent,
  // CardDescription,
  // CardHeader,
  // GoldenRatioCard,
  // GoldenRatioCardContent,
  // GoldenRatioCardHeader,
  // TypographyH2,
  // TypographyP,
} from '@demeter/shadcn-ui';
import { ArrowRight } from 'lucide-react';
import Link from 'next/link';
import { Suspense } from 'react';

import { NpoFooter } from '../../../../../components/NpoFooter/NpoFooter';
import { NpoHeader } from '../../../../../components/NpoHeader/NpoHeader';
import { NpoMenu } from '../../../../../components/NpoMenu/NpoMenu';
// import { faApple, faAndroid } from '@fortawesome/free-brands-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Autoplay from 'embla-carousel-autoplay';
// import { Suspense } from 'react';

// import BannerImageWrapper from '../../../../../components/BannerImage/BannerImageWrapper';

export default function Layout({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode {
  return (
    <>
      {/* <SiteHeader title={'MusicLocal'} titleLink="/" /> */}
      <NpoHeader />
      {/* <div className="!h-[38.2vh] !max-h-[38.2vh] !min-h-[38.2vh] overflow-clip">
        <SwiperLayout>
          <SwiperSlide>
            <SwiperSlideContent className="!h-[38.2vh] !max-h-[38.2vh] !min-h-[38.2vh]">
              <BannerWrapper aspectRatio={ASPECT_16_9}>
                <Suspense>
                  <BannerImageWrapper />
                </Suspense>
              </BannerWrapper>
            </SwiperSlideContent>
          </SwiperSlide>
        </SwiperLayout>
      </div> */}
      {/* <SwiperSlide> */}
      {/* <SwiperSlideContent className="!min-h-[23.6vh]"> */}
      {/* 
        This section was originally:
        className="container ml-auto mr-auto mt-[50px] w-full border-b"
        We update to:
        - container mx-auto for horizontal centering
        - mt-12 for consistent spacing
        - px-4 for horizontal padding
        while preserving border-b
      */}
      <section
        style={{ backgroundColor: '#0f172a' }}
        className="bg-background mx-auto mt-12 w-full"
        // className="mx-auto mt-12 w-full bg-gradient-to-r from-slate-950 via-slate-900 to-slate-950"
      >
        <div className="slate-900 container px-4">
          <div className="flex flex-col items-start gap-1 py-12">
            <Link
              className="group mb-2 inline-flex items-center px-0.5 text-base font-medium"
              href="/tallahassee"
            >
              <span className="underline-offset-4 group-hover:underline">
                View Tallahassee Events
              </span>
              <ArrowRight className="ml-1 h-5 w-5" />
            </Link>
            <h1 className="text-5xl font-bold leading-[1.2] tracking-tight">
              Share Live Music
            </h1>
            <p className="text-muted-foreground max-w-2xl text-lg leading-relaxed">
              We empower musicians, venues, and listeners&nbsp;
              <br className="hidden sm:inline" />
              to build thriving local communities.
            </p>
            <div className="flex w-full items-center justify-start gap-2 pt-2">
              <Button variant="default" asChild>
                <Link className="hover:underline" href="/tallahassee">
                  View Events
                </Link>
              </Button>
              <Button variant="secondary" asChild>
                <Link className="hover:underline" href="/npo/donate">
                  Donate
                </Link>
              </Button>
            </div>
          </div>
        </div>
      </section>

      <NpoMenu />

      <section className="container mx-auto w-full px-4 py-6 leading-relaxed">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          {/* 
            Updated each card to .rounded-lg 
            and ensured consistent text sizing:
          */}
          <Link href="/tallahassee">
            <div className="bg-card text-card-foreground rounded-lg border shadow">
              <div className="flex flex-row items-center justify-between p-6 pb-2">
                <div className="text-muted-foreground text-sm font-semibold">
                  Upcoming Events
                </div>
              </div>
              <div className="p-6 pt-0">
                <div className="text-2xl font-bold">
                  <Suspense
                    fallback={
                      <Skeleton className="max-w-24 px-4 text-2xl font-bold">
                        &nbsp;
                      </Skeleton>
                    }
                  >
                    {children}
                  </Suspense>
                </div>
                <p className="text-muted-foreground text-sm">
                  Near Tallahassee
                </p>
              </div>
            </div>
          </Link>
          {/* <div className="bg-card text-card-foreground rounded-xl border shadow">
            <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-2">
              <div className="text-sm font-normal tracking-tight">
                Total Events
              </div>
            </div>
            <div className="p-6 pt-0">
              <div className="text-2xl font-bold">1082 Events</div>
              <p className="text-muted-foreground text-xs">
                Listed Since Launch
              </p>
            </div>
          </div> */}
          <div className="bg-card text-card-foreground rounded-lg border shadow">
            <div className="flex flex-row items-center justify-between p-6 pb-2">
              <div className="text-muted-foreground text-sm font-semibold">
                Get the App
              </div>
            </div>
            <div className="p-6 pt-0">
              <div className="flex w-full flex-col items-start justify-center space-x-1">
                <a
                  href="https://apps.apple.com/us/app/musiclocal/id6470649809"
                  target="_blank"
                  aria-label="Download on iOS"
                  className="text-primary flex items-center hover:underline"
                >
                  {/* 
                    FontAwesomeIcon usage remains commented out. 
                    Keeping existing icons:
                  */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    className="mr-2 h-8 w-8 fill-current"
                  >
                    <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
                  </svg>
                  <span>iOS</span>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=org.musiclocal.app"
                  target="_blank"
                  aria-label="Download on Android"
                  className="text-primary flex items-center hover:underline"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    className="mr-2 h-8 w-8 fill-current"
                  >
                    <path d="M420.6 301.9a24 24 0 1 1 24-24 24 24 0 0 1 -24 24m-265.1 0a24 24 0 1 1 24-24 24 24 0 0 1 -24 24m273.7-144.5 47.9-83a10 10 0 1 0 -17.3-10h0l-48.5 84.1a301.3 301.3 0 0 0 -246.6 0L116.2 64.5a10 10 0 1 0 -17.3 10h0l47.9 83C64.5 202.2 8.2 285.6 0 384H576c-8.2-98.5-64.5-181.8-146.9-226.6" />
                  </svg>
                  <span>Android</span>
                </a>
              </div>
            </div>
          </div>
          {/* <div className="bg-card text-card-foreground rounded-lg border shadow">
            <div className="flex flex-row items-center justify-between p-6 pb-2">
              <div className="text-muted-foreground text-sm font-semibold">
                Get Inspired
              </div>
            </div>
            <div className="flex flex-row items-center justify-between p-6 pb-2 pt-0">
              <div className="text-sm font-bold tracking-tight">
                Technology has a purpose, and the purpose is to do good and to
                share.
              </div>
            </div>
            <div className="p-6 pt-0">
              <p className="text-muted-foreground text-sm">
                &mdash;Steve Wozniak
              </p>
            </div>
          </div> */}
        </div>
      </section>
      {/* <section className="ml-auto mr-auto w-full px-4 py-6">
        <div className="flex flex-row justify-between">
          <div className="bg-card text-card-foreground flex h-[15vw] w-[15vw] items-center justify-center rounded-[50%] border shadow">
            <div className="items-center justify-center p-6 text-center font-bold">
              More Discoverable, Available Live Music
            </div>
          </div>
          <div className="bg-card text-card-foreground flex h-[15vw] w-[15vw] items-center justify-center rounded-[50%] border shadow">
            <div className="items-center justify-center p-6 text-center font-bold">
              Increased In-Person Interaction
            </div>
          </div>
          <div className="bg-card text-card-foreground flex h-[15vw] w-[15vw] items-center justify-center rounded-[50%] border shadow">
            <div className="items-center justify-center p-6 text-center font-bold">
              Healthier Engagement, Less Reliance on Social Media
            </div>
          </div>
          <div className="bg-card text-card-foreground flex h-[15vw] w-[15vw] items-center justify-center rounded-[50%] border shadow">
            <div className="items-center justify-center p-6 text-center font-bold">
              Stronger Community Ties and Trust
            </div>
          </div>
          <div className="bg-card text-card-foreground flex h-[15vw] w-[15vw] items-center justify-center rounded-[50%] border shadow">
            <div className="items-center justify-center p-6 text-center font-bold">
              Thriving Local Communities
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="ml-auto mr-auto w-full px-4 py-6">
        <Carousel
          className="w-full"
          opts={{
            loop: true,
            align: 'start',
          }}
          plugins={[
            Autoplay({
              delay: 4000,
            }),
          ]}
        >
          <CarouselContent>
            <CarouselItem className="flex basis-1/5 items-center justify-center">
              <div className="bg-card text-card-foreground flex h-[15vw] w-[15vw] items-center justify-center rounded-[50%] border shadow">
                <div className="items-center justify-center p-6 text-center font-bold">
                  More Discoverable Live Music
                </div>
              </div>
            </CarouselItem>
            <CarouselItem className="flex basis-1/5 items-center justify-center">
              <div className="bg-card text-card-foreground flex h-[15vw] w-[15vw] items-center justify-center rounded-[50%] border shadow">
                <div className="items-center justify-center p-6 text-center font-bold">
                  Healthier Social Engagement
                </div>
              </div>
            </CarouselItem>
            <CarouselItem className="flex basis-1/5 items-center justify-center">
              <div className="bg-card text-card-foreground flex h-[15vw] w-[15vw] items-center justify-center rounded-[50%] border shadow">
                <div className="items-center justify-center p-6 text-center font-bold">
                  Stronger Civic Ties and Trust
                </div>
              </div>
            </CarouselItem>
            <CarouselItem className="flex basis-1/5 items-center justify-center">
              <div className="bg-card text-card-foreground flex h-[15vw] w-[15vw] items-center justify-center rounded-[50%] border shadow">
                <div className="items-center justify-center p-6 text-center font-bold">
                  Flourishing Local Economies
                </div>
              </div>
            </CarouselItem>
            <CarouselItem className="flex basis-1/5 items-center justify-center">
              <div className="bg-card text-card-foreground flex h-[15vw] w-[15vw] items-center justify-center rounded-[50%] border shadow">
                <div className="items-center justify-center p-6 text-center font-bold">
                  Thriving Communities
                </div>
              </div>
            </CarouselItem>
            <CarouselItem className="flex basis-1/5 items-center justify-center">
              <div className="bg-card text-card-foreground flex h-[15vw] w-[15vw] items-center justify-center rounded-[50%] border shadow">
                <div className="items-center justify-center p-6 text-center font-bold">
                  Positive Cycle of Impact
                </div>
              </div>
            </CarouselItem>
          </CarouselContent>
        </Carousel>
      </section> */}

      {/* <div className="container mt-[50px] flex !min-h-[23.6vh] p-0">
        <GoldenRatioCard className="grow-1 mt-1 flex w-full flex-col p-0">
          <GoldenRatioCardHeader>
            <TypographyH2>MusicLocal</TypographyH2>
            <CardDescription>
              Supporting live, local music{' '}
              <span style={{ marginTop: 3 }}>❤️</span>
            </CardDescription>
          </GoldenRatioCardHeader>
          <GoldenRatioCardContent className="pb-2">
            <TypographyP>
              Now listing live music events in{' '}
              <Link
                href="/tallahassee"
                className="text-primary hover:underline"
              >
                Tallahassee, FL.
              </Link>
            </TypographyP>
          </GoldenRatioCardContent>
        </GoldenRatioCard>
      </div> */}
      {/* <div className="container mt-1 flex h-full !min-h-[38.2vh] p-0">
        <Card className="grow-1 flex w-full flex-col items-center justify-center p-0">
          <CardHeader className="flex flex-col items-center justify-center text-center">
            <TypographyH2>
              <Link
                href="/tallahassee"
                className="text-primary hover:underline"
              >
                View Tallahassee Music Events
              </Link>
            </TypographyH2>
          </CardHeader>
          <CardContent className="text-center leading-relaxed">
            MusicLocal is a community-driven platform that supports local, live
            music. We currently list live music events in Tallahassee, FL.
          </CardContent>
        </Card>
      </div> */}
      {/* <div className="container mt-1 flex h-full p-0">
        <Card className="grow-1 flex w-full flex-col items-center justify-center p-0">
          <CardHeader className="flex flex-col items-center justify-center p-4 text-center leading-relaxed">
            MusicLocal is available on mobile:
          </CardHeader>
          <CardContent className="text-center">
            <div className="mt-2 flex w-full items-center justify-center space-x-4">
              <a
                href="https://apps.apple.com/us/app/musiclocal/id6470649809"
                target="_blank"
                aria-label="Download on iOS"
                className="text-primary flex items-center hover:underline"
              > */}
      {/* <FontAwesomeIcon icon={faApple} size="1x" className="mr-2" /> */}
      {/* <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--> */}
      {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  className="mr-2 h-16 w-16 fill-current"
                >
                  <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
                </svg>
                <span>Download on iOS</span>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=org.musiclocal.app"
                target="_blank"
                aria-label="Download on Android"
                className="text-primary flex items-center hover:underline"
              > */}
      {/* <FontAwesomeIcon icon={faAndroid} size="1x" className="mr-2" /> */}
      {/* <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--> */}
      {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                  className="mr-2 h-16 w-16 fill-current"
                >
                  <path d="M420.6 301.9a24 24 0 1 1 24-24 24 24 0 0 1 -24 24m-265.1 0a24 24 0 1 1 24-24 24 24 0 0 1 -24 24m273.7-144.5 47.9-83a10 10 0 1 0 -17.3-10h0l-48.5 84.1a301.3 301.3 0 0 0 -246.6 0L116.2 64.5a10 10 0 1 0 -17.3 10h0l47.9 83C64.5 202.2 8.2 285.6 0 384H576c-8.2-98.5-64.5-181.8-146.9-226.6" />
                </svg>
                <span>Download on Android</span>
              </a>
            </div>
          </CardContent>
        </Card>
      </div> */}
      <div className="p-8"></div>
      {/* </SwiperSlideContent> */}
      {/* </SwiperSlide> */}
      {/* <Suspense fallback={<EventsListSkeleton />}>{children}</Suspense> */}
      <NpoFooter />
      <SiteFooter />
    </>
  );
}
